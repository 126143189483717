import styled from "styled-components";
import Card from "react-bootstrap/Card";

export const CLDResultCard = styled(Card)`
    margin-top: 15px;
`

export const CLDResultCardBody = styled(Card.Body)`
    background-color: #002855;
`

export const CLDResultCardTitle = styled(Card.Title)`
    color: #E84A27;
    padding: 15px;
`

export const CLDResultCardText = styled(Card.Text)`
    height: 10ex;
    margin: 0 15px;
    line-height: 2.5ex;
    overflow: hidden;
`
export const CLDResultsListDiv = styled.div`
    margin-top: 15px;
`

export const CLDResultsDiv = styled.div`
    min-height: 100vh;
`


