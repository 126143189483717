import React, {useState, useEffect, useRef } from 'react'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import CLDNavBar from "./styledComponents/CLDNavBar";
import {CLDFooter} from "./styledComponents/CLDFooter";
import {data_vis_db, data_vis_onSearch_cat_CLD, get_db_Detail, get_db_docStat} from "../utils/api";
import { Pie, Bar, getElementAtEvent } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import {getColorList} from "./styledComponents/CLDPlotColor";
import {Page} from "./styledComponents/CLDPages";

const CLDHomeCard = styled(Card)`
  background-color: #002855;
  margin-top: 5vh;
`

const CLDPieChartCard = styled(Card)`
  background-color: #002855;
  margin-top: 2vh;
`

const CLDPieChartCard_Body = styled(Card.Body)`
  padding-left: 0 !important;
  padding-right: 0 !important;

`

export const CLDHomeCardTitle = styled(Card.Title)`
    color: #E84A27;
    padding: 15px;
`

const StyledBarDiv = styled.div`
    display: flex;
    margin: 30px auto;
    min-height: 1000px;
`

const StyledTwinDiv = styled.div`
  display: flex;
  margin: 15px;
  min-height: 650px;
`

const StyledBarDiv1 = styled.div`
    display: flex;
    width: auto;
    margin: 30px auto;
`

const StyledBarDiv2 = styled.div`
  display: flex;
  margin: 30px auto;
`

const CLDHomeContainer = styled(Container)`
  min-height: 100vh;
`

function Home() {
    const [data, setData] = useState('');
    const [dataVis, setDataVis] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [clusterNum, setClusterNum] = useState(0);
    const [yearNum, setYearNum] = useState(0);
    const [yearList, setYearList] = useState([]);
    const [clusterList, setClusterList] = useState([]);
    const [selectedCluster, setSelectedCluster] = useState('');
    const [chartDataYearXCatY, setChartDataYearXCatY] = useState({});
    const [article_by_year_category, setArticle_by_year_category] = useState({});
    const [article_by_category_year, setArticle_by_category_year] = useState({});

    const [chartDataCatXNumberY, setChartDataCatXNumberY] = useState({});
    const [chartDataYearXNumberY, setChartDataYearXNumberY] = useState({});

    const cluster_names = ['Detection', 'Cyber Terminology', 'Cyber System Management', 'Cyber Regulations & Public Policy', 'Healthcare',
        'Computational Intelligence', 'Mobile', 'System Security', 'Misc. I', 'Finance & Economics', 'Security Breach',
        'Misc. II', 'Technology Management', 'Malware', 'Cyberphysical Devices', 'Misc. III', 'Electronic Control',
        'Cyber Crimes', 'System Resilience', 'Cryptography', 'Algorithm', 'Data Management', 'Misc. IV',
        'Internet of Things', 'Smart Network', 'Power System', 'Assessment', 'Learning', 'Cyber Security', 'Cyber Attack']


    const chartRef = useRef();
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );
    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Number of Articles per year by Cluster',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        maintainAspectRatio: false,
    };
    const optionClusterBar = {
        plugins: {
            title: {
                display: true,
                    text: `Number of Literature Pieces in "${cluster_names[selectedCluster]}"`
            },
            legend: {
                display: true,
                    position: "bottom"
            }
        },
        maintainAspectRatio: true,
        aspectRatio: 1 | 1,
    }
    const optionsYearPie = {
        plugins: {
            title: {
                display: true,
                text: `Number of Literature Pieces in "${selectedYear}"`
            },
            legend: {
                display: true,
                position: "bottom"
            }
        },
        maintainAspectRatio: true,
        aspectRatio: 1 | 1,
    }


    const onClick = (event) => {
        console.log(getElementAtEvent(chartRef.current, event));
    }

    React.useState(() => {
        get_db_Detail().then(res => {
            setData(res.data)
        })
        data_vis_db().then(res => {
            setDataVis(res.data)
            setArticle_by_year_category(res.data.article_by_year_category)
            setArticle_by_category_year(res.data.article_by_category_year)
            const article_by_year_category = res.data.article_by_year_category
            setClusterList(Object.keys(article_by_year_category))
            setClusterNum(Object.keys(article_by_year_category).length)
            const article_by_category_year = res.data.article_by_category_year
            setYearList(Object.keys(article_by_category_year).reverse())
            setYearNum(Object.keys(article_by_category_year).length)
            // console.log(article_by_year_category["None"], Object.keys(article_by_year_category))

            // console.log(article_by_year_category)
            // console.log(article_by_category_year)
            let dataValueList = []
            let iColor = 0
            for (let cat in article_by_year_category) {
                const thisValueList = {
                    label: `Number of Articles in ${cluster_names[cat]}`,
                    data: Object.values(article_by_year_category[cat]),
                    backgroundColor: getColorList(Object.keys(article_by_year_category).length)[0][iColor]
                }
                dataValueList.push(thisValueList)
                iColor++
            }
            setChartDataYearXCatY({
                labels: Object.keys(article_by_category_year),
                datasets: dataValueList,
            })

        })
    })

    const changeSelectYear = ({target: {value}}) => {
        if (value !== undefined && value !== "") {
            let labels_list = []
            for (let cat in Object.keys(article_by_category_year[value])) {
                labels_list.push(cluster_names[cat])
            }
            setChartDataCatXNumberY({
                labels: labels_list,
                datasets: [{
                    label: `Number of Articles in ${value}`,
                    data: Object.values(article_by_category_year[value]),
                    backgroundColor: getColorList(clusterNum)[1],
                    borderColor: getColorList(clusterNum)[0],
                }]
            })
            setSelectedYear(value)
        }
    }

    const changeSelectCluster = ({target: {value}}) => {
        if (value !== undefined && value !== "") {
            setChartDataYearXNumberY({
                labels: Object.keys(article_by_year_category[value]),
                datasets: [{
                    label: `Number of Articles in ${cluster_names[value]}`,
                    data: Object.values(article_by_year_category[value]),
                    backgroundColor: getColorList(yearNum)[1],
                    borderColor: getColorList(yearNum)[0],
                }]
            })
            setSelectedCluster(value)
        }
    }

    return (
        <Page className="Homepage">
            <CLDNavBar />
            <CLDHomeContainer>
                <Row>
                    <CLDHomeCard>
                        <Card.Body>
                            <CLDHomeCardTitle className="font-weight-bold lead">Repository Status</CLDHomeCardTitle>
                            <hr color='white'/>
                            {data?(<Card.Text className="text-white lead">

                                <p><b>Number of articles: </b> {data.num_articles}</p>
                                <p><b>Publication Sources:</b> {data.num_sources}</p>
                                <p><b>Last Updated:</b> {data.last_updated} </p>
                                <p><b>View Count:</b> {data.view_count} </p>

                            </Card.Text>):(<Card.Text className="text-white lead">

                                <p><b>Number of articles: </b> 15065</p>
                                <p><b>Publication Sources:</b> 4540</p>
                                <p><b>Last Updated:</b> April 19, 2022 </p>

                            </Card.Text>)}
                        </Card.Body>
                    </CLDHomeCard>
                </Row>
                <Row>
                    {dataVis
                        ? (<>
                            <StyledBarDiv className="row">
                                <Bar options={options} data={chartDataYearXCatY}/>
                            </StyledBarDiv>
                        </>)
                        : (<StyledBarDiv2 className="row">
                            <StyledBarDiv1>
                                <Spinner animation="border" />
                            </StyledBarDiv1>
                        </StyledBarDiv2>)}
                </Row>
                <Row>
                    <Col sm={6}>
                        {dataVis
                            ? (<>
                                <StyledTwinDiv className="row">
                                        <CLDPieChartCard>
                                            <CLDPieChartCard_Body>
                                                <CLDHomeCardTitle className="font-weight-bold lead">Number of Articles by Cluster in Year</CLDHomeCardTitle>
                                                <hr color='white'/>
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    onChange={changeSelectYear}
                                                    value={selectedYear}
                                                >
                                                    <option value="">Choose Year</option>
                                                    {yearList.map(year => (<option value={year}>{year}</option>))}
                                                </Form.Select>
                                                {selectedYear && <Pie data={chartDataCatXNumberY} options={optionsYearPie}/>}
                                            </CLDPieChartCard_Body>
                                        </CLDPieChartCard>
                                </StyledTwinDiv>
                            </>)
                            : (<StyledBarDiv2 className="row">
                                <StyledBarDiv1>
                                    <Spinner animation="border" />
                                </StyledBarDiv1>
                            </StyledBarDiv2>)}
                    </Col>
                    <Col sm={6}>
                        {dataVis
                            ? (<>
                                <StyledTwinDiv className="row">
                                        <CLDPieChartCard>
                                            <CLDPieChartCard_Body>
                                                <CLDHomeCardTitle className="font-weight-bold lead">Number of Articles by Year in Cluster</CLDHomeCardTitle>
                                                <hr color='white'/>
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    onChange={changeSelectCluster}
                                                    value={selectedCluster}
                                                >
                                                    <option value="">Choose Cluster</option>
                                                    {clusterList.map(cluster => (<option value={cluster}>{cluster_names[cluster]}</option>))}
                                                </Form.Select>
                                                {selectedCluster && <Bar data={chartDataYearXNumberY} options={optionClusterBar}/>}
                                            </CLDPieChartCard_Body>
                                        </CLDPieChartCard>
                                </StyledTwinDiv>
                            </>)
                            : (<StyledBarDiv2 className="row">
                                <StyledBarDiv1>
                                    <Spinner animation="border" />
                                </StyledBarDiv1>
                            </StyledBarDiv2>)}
                    </Col>

                </Row>
            </CLDHomeContainer>
            <CLDFooter />
        </Page>
    )
}

export default Home;