
export function getColorList(num) {
    let colorList = []
    let colorListInner = []
    for (let i = 0; i < num; i++) {
        let r = Math.floor(50 + 255 * i/num)
        if (r > 255) r = r - 255
        let g = Math.floor(50 + 255 * (num - i)/num)
        if (g > 255) g = g - 255
        if (g < 0) g = g + 255
        let b = Math.floor(200 - Math.abs(r/g))
        if (b < 0) b = b + 255

        colorList.push(`rgba(${r}, ${g}, ${b}, 1)`)
        colorListInner.push(`rgba(${r}, ${g}, ${b}, 0.4)`)
    }

    return [colorList, colorListInner]
}

const r1 = 24

export const colorListCat = [
    'rgba(55, 187, 58, 1)',
    'rgba(148, 151, 94, 1)',
    'rgba(94, 135, 151, 1)',
    'rgba(54, 95, 191, 1)',
    'rgba(54, 164, 191, 1)',
    'rgba(54, 191, 150, 1)',
    'rgba(94, 151, 119, 1)',
    'rgba(207, 152, 226, 1)',
    'rgba(72, 191, 54, 1)',
    'rgba(191, 184, 54, 1)',
    'rgba(191, 113, 54, 1)',
    'rgba(191, 56, 54, 1)',
    'rgba(191, 54, 134, 1)',
    'rgba(138, 54, 191, 1)',
    'rgba(84, 54, 191, 1)',
]

export const colorListCatInner = [
    'rgba(187, 187, 58, 0.4)',
    'rgba(148, 151, 94, 0.4)',
    'rgba(94, 135, 151, 0.4)',
    'rgba(54, 95, 191, 0.4)',
    'rgba(54, 164, 191, 0.4)',
    'rgba(54, 191, 150, 0.4)',
    'rgba(94, 151, 119, 0.4)',
    'rgba(207, 152, 226, 0.4)',
    'rgba(72, 191, 54, 0.4)',
    'rgba(191, 184, 54, 0.4)',
    'rgba(191, 113, 54, 0.4)',
    'rgba(191, 56, 54, 0.4)',
    'rgba(191, 54, 134, 0.4)',
    'rgba(138, 54, 191, 0.4)',
    'rgba(84, 54, 191, 0.4)',
]

export const colorListYear = [
    'rgba(187, 187, 58, 1)',
    'rgba(54, 95, 191, 1)',
    'rgba(54, 164, 191, 1)',
    'rgba(191, 54, 134, 1)',
    'rgba(138, 54, 191, 1)',
    'rgba(0, 167, 245, 1)',
    'rgba(191, 184, 54, 1)',
    'rgba(84, 54, 191, 1)',
    'rgba(148, 151, 94, 1)',
    'rgba(94, 135, 151, 1)',
    'rgba(245, 0, 0, 1)',
    'rgba(54, 191, 150, 1)',
    'rgba(94, 151, 119, 1)',
    'rgba(207, 152, 226, 1)',
    'rgba(72, 191, 54, 1)',
    'rgba(0, 245, 228, 1)',
    'rgba(191, 113, 54, 1)',
    'rgba(191, 56, 54, 1)',
    'rgba(245, 118, 0, 1)',
    'rgba(245, 192, 0, 1)',
    'rgba(216, 245, 0, 1)',
    'rgba(94, 245, 0, 1)',
    'rgba(0, 245, 110, 1)',
]

export const colorListYearInner = [
    'rgba(187, 187, 58, 0.4)',
    'rgba(54, 95, 191, 0.4)',
    'rgba(54, 164, 191, 0.4)',
    'rgba(191, 54, 134, 0.4)',
    'rgba(138, 54, 191, 0.4)',
    'rgba(0, 167, 245, 0.4)',
    'rgba(191, 184, 54, 0.4)',
    'rgba(84, 54, 191, 0.4)',
    'rgba(148, 151, 94, 0.4)',
    'rgba(94, 135, 151, 0.4)',
    'rgba(245, 0, 0, 0.4)',
    'rgba(54, 191, 150, 0.4)',
    'rgba(94, 151, 119, 0.4)',
    'rgba(207, 152, 226, 0.4)',
    'rgba(72, 191, 54, 0.4)',
    'rgba(0, 245, 228, 0.4)',
    'rgba(191, 113, 54, 0.4)',
    'rgba(191, 56, 54, 0.4)',
    'rgba(245, 118, 0, 0.4)',
    'rgba(245, 192, 0, 0.4)',
    'rgba(216, 245, 0, 0.4)',
    'rgba(94, 245, 0, 0.4)',
    'rgba(0, 245, 110, 0.4)',
]