import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

export const CLDDetailPage = styled.div`
  margin-bottom: 0;
  padding: 0;
`

export const CLDDetailContainer = styled.div`
    max-width: 1200px;
    justify-content: center;

`

export const CLDDetailTitleDiv = styled.div`
  background-color: #002855;
  padding: 50px 15vw 30px 15vw;
  color: white;
  position: relative;
`

export const CLDDetailDesDiv = styled.div`
  padding: 10px 15vw 30px 15vw;
  text-align: left;
  position: relative;
`

export const CLDDetailCardDiv = styled.div`
  text-align: left;
  position: relative;
  padding: 50px 15vw 30px 15vw;
`

export const CLDDetailTextDiv = styled.div`
  max-width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  align-content: center;
`

export const CLDDetailClusterCard = styled(Card)`

`

export const WordCloudContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-top: 30px;
`

export const WordCloudDiv = styled.div`
  width: 320px;
  height: 380px;
`

export const WordCloudFocusDiv = styled.div`
  width: 800px;
  height: 1000px;
`

export const WordCloudImg = styled.img`
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 300px;
`

export const WordCloudImgFocus = styled.img`
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  height: 800px;
`

export const WordCloudP = styled.p`
  text-align: center;
`

export const ClusterDiv = styled.div`
  min-height: 100vh;    
`