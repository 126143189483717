import React from "react";
import {
    Box,
    Row,
    Column,
    Content,
    FooterLink,
    Heading, LinkInContent, CLDimg,
} from "./CLDFooterStyles";

import logo from "../images/logo.png";
import SoAlogo from "../images/SOA_research_institute_logo.png";
import CAElogo from "../images/CAELogo.jpeg";
import Container from "react-bootstrap/Container";
import styled from "styled-components"

const CLDFooterDiv = styled.div`
  
`


export function CLDFooter() {


    const SOAlink = (<LinkInContent href="https://www.soa.org/"> Society of Actuaries Research Institute (SOA)</LinkInContent>)
    return (
        <CLDFooterDiv className="CLDFooter">
            <Box>
                {/*<h1 style={{ color: "#E84A27",*/}
                {/*    textAlign: "center",*/}
                {/*    marginTop: "-50px" }}>*/}
                {/*    GeeksforGeeks: A Computer Science Portal for Geeks*/}
                {/*</h1>*/}
                <Container>
                    <Row>
                        <Column>
                            <Heading>Acknowledgement</Heading>
                        </Column>
                    </Row>
                    <Row>
                        <CLDimg
                            alt=""
                            src={SoAlogo}
                            width="155"
                            height="75"
                        />
                        <CLDimg
                            alt=""
                            src={CAElogo}
                            width="120"
                            height="75"
                        />
                    </Row>
                    <Row>
                            <Content>The research team gladly acknowledges the funding support provided by the
                            {SOAlink}. Any opinions, findings, and conclusions or recommendations from this research
                        project are those of ours and do not necessarily reflect the views of the SOA.</Content>
                    </Row>
                </Container>
            </Box>
        </CLDFooterDiv>
    )
}