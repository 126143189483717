import React, {useState} from "react"
import Plot from 'react-plotly.js';


export default function CLDPCA (props) {
    const {clusterId} = props


    const cluster_names = ['Detection', 'Cyber Terminology', 'Cyber System Management', 'Cyber Regulations & Public Policy', 'Healthcare',
        'Computational Intelligence', 'Mobile', 'System Security', 'Misc. I', 'Finance & Economics', 'Security Breach',
        'Misc. II', 'Technology Management', 'Malware', 'Cyberphysical Devices', 'Misc. III', 'Electronic Control',
        'Cyber Crimes', 'System Resilience', 'Cryptography', 'Algorithm', 'Data Management', 'Misc. IV',
        'Internet of Things', 'Smart Network', 'Power System', 'Assessment', 'Learning', 'Cyber Security', 'Cyber Attack']

    const edges = [[2, 15], [7, 15], [10, 15], [14, 15], [18, 15], [22, 15], [28, 15], [10, 7], [14, 7], [15, 7]]

    const keyword_counts = [1150, 2000, 1392, 1855, 495, 1090, 377, 1588, 944, 1169, 1597, 1002, 1315, 800, 925, 2015, 1365, 753, 1787, 526, 1322, 2131, 2835, 845, 802, 809, 2347, 761, 1242, 804]

    const paper_counts = [4866,5086,6588,3966,971,5709,1075,9372,2821,3114,6104,3038,5203,3289,6813,10097,5017,2711,6114,1454,2536,6147,6895,4295,2920,2115,6676,3254,16868,3280]

    const scatter_points = [
        [ 0.52208377, -0.28785693],
        [-2.38978562,  2.0458113 ],
        [-2.32596376, -2.43464674],
        [-0.06081175,  2.76768179],
        [ 2.34664609, -1.65470467],
        [ 4.77951248, -0.10020074],
        [ 3.03087417,  0.63112168],
        [ 2.39792831, -4.49763533],
        [-2.7807682 ,  3.06312955],
        [ 1.06902506, -0.30980597],
        [-2.59666633,  3.16231075],
        [-1.72223275,  0.2134087 ],
        [ 1.29108682, -1.3532924 ],
        [ 3.1409128 ,  3.70922132],
        [ 7.15578153,  1.55139866],
        [-4.91380946, -0.72922784],
        [ 1.17493331, -0.05493947],
        [ 7.14953108,  3.69660287],
        [-3.14785513, -4.79453867],
        [ 3.52769252, -0.08374717],
        [ 0.78980288,  1.03881145],
        [ 0.04607178, -0.89407022],
        [-4.92243577,  2.45099754],
        [ 4.67714856,  1.20014924],
        [ 1.59902879, -4.3484019 ],
        [ 1.28646386,  0.3198204 ],
        [-2.07173095, -2.03577438],
        [ 0.5146706 , -2.94748002],
        [ 7.66222526, -0.94992739],
        [ 0.99684286,  4.08228602]
    ]

    const useID = clusterId !== undefined && clusterId >= 0 && clusterId <= cluster_names.length
    const filtered_edge = []
    const display_nodes = []
    const display_nodes_id = []
    if (useID) {
        display_nodes.push(scatter_points[clusterId])
        display_nodes_id.push(parseInt(clusterId))
        for (let i in edges) {
            if (edges[i].includes(parseInt(clusterId))) {
                filtered_edge.push(edges[i])
                const [e0, e1] = edges[i]
                if (!display_nodes.includes(scatter_points[e0])) {
                    display_nodes.push(scatter_points[e0])
                    display_nodes_id.push(e0)
                }
                if (!display_nodes.includes(scatter_points[e1])) {
                    display_nodes.push(scatter_points[e1])
                    display_nodes_id.push(e1)
                }
            }
        }
    }


    const data_list = []
    let edge_pointer = edges
    let node_pointer = scatter_points
    if (useID) {
        edge_pointer = filtered_edge
        node_pointer = display_nodes
    }
    for (let point in edge_pointer) {
        let node_0 = edge_pointer[point][0]
        let node_1 = edge_pointer[point][1]
        let [x_0, y_0] = scatter_points[node_0]
        let [x_1, y_1] = scatter_points[node_1]

        data_list.push({
            x: [x_0, x_1],
            y: [y_0, y_1],
            name: `${cluster_names[node_0]} (C${node_0}) -> ${cluster_names[node_1]} (C${node_1})`,
            type: 'scatter',
            text: "",
            hoverinfo: 'text',
            mode: 'lines',
            marker: {color: 'lightgreen'},
        })
    }

    const size_list = []
    const labels = []
    const hover_text = []

    const scale_factor = 20 * keyword_counts.length / keyword_counts.reduce((partial, a) => partial + a, 0)
    let node_id_pointer = keyword_counts
    if (useID) node_id_pointer = display_nodes_id
    for (let i in node_id_pointer) {
        if (useID) {
            size_list.push(keyword_counts[display_nodes_id[i]] * scale_factor)
        } else {
            size_list.push(keyword_counts[i] * scale_factor)
        }

    }

    const data_x = []
    const data_y = []

    for (let point in node_pointer) {
        data_x.push(node_pointer[point][0])
        data_y.push(node_pointer[point][1])
        if (useID) {
            labels.push(`C${parseInt(display_nodes_id[point]) + 1}`)
            hover_text.push(`Keyword Cluster C${parseInt(display_nodes_id[point]) + 1} <br> ${cluster_names[display_nodes_id[point]]} <br> # of Keywords ${keyword_counts[display_nodes_id[point]]} <br> # of Papers ${paper_counts[display_nodes_id[point]]}`)
        } else {
            labels.push(`C${parseInt(point) + 1}`)
            hover_text.push(`Keyword Cluster C${parseInt(point) + 1} <br> ${cluster_names[point]} <br> # of Keywords ${keyword_counts[point]} <br> # of Papers ${paper_counts[point]}`)
        }

    }

    data_list.push({
        x: data_x,
        y: data_y,
        mode: 'markers+text',
        marker: {
            size: size_list,
            color: 'turquoise',
            opacity: 0.6,
        },
        text: labels,
        textposition: 'middle center',
        textfont: {
            family: 'Arial',
            size: 13
        },
        hoverinfo: 'text',
        hovertext: hover_text,
        name: 'KMeans Centroids',
    })

    const layout = {
        width: 1200,
        height: 750,
        title: 'Associations between Clusters',
        plot_bgcolor: 'white',
        hovermode: 'closest',
        xaxis: {title: '', showticklabels: false, visible: false},
        yaxis: {title: ' ', showticklabels: false, visible: false,},
    }


    return (
        <Plot
            data={data_list}
            layout={layout}
        />
    )
}