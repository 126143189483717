import React, {useState} from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CLDNavBar from "./styledComponents/CLDNavBar";
import {
    CLDDetailCardDiv,
    CLDDetailClusterCard,
    CLDDetailDesDiv,
    CLDDetailPage,
    CLDDetailTextDiv,
    CLDDetailTitleDiv, WordCloudContainer, WordCloudDiv, WordCloudImg, WordCloudP
} from "./styledComponents/CLDDetail";

import {useParams} from "react-router";
import {Page} from "./styledComponents/CLDPages";
import {CLDFooter} from "./styledComponents/CLDFooter";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import wordCloudLoader from "./images/wordCloud";
import {useNavigate} from "react-router-dom";


export default function DocDetail(props) {
    const navigate = useNavigate()
    const {query, docType} = useParams();
    const [article,setArticle] = useState();
    const [clusterList, setClusterList] = useState([]);
    const [doi, setDoi] = useState("");
    const [wordCloudList, setWordCloudList] = useState([]);
    const cluster_names = ['Detection', 'Cyber Terminology', 'Cyber System Management', 'Cyber Regulations & Public Policy', 'Healthcare',
        'Computational Intelligence', 'Mobile', 'System Security', 'Misc. I', 'Finance & Economics', 'Security Breach',
        'Misc. II', 'Technology Management', 'Malware', 'Cyberphysical Devices', 'Misc. III', 'Electronic Control',
        'Cyber Crimes', 'System Resilience', 'Cryptography', 'Algorithm', 'Data Management', 'Misc. IV',
        'Internet of Things', 'Smart Network', 'Power System', 'Assessment', 'Learning', 'Cyber Security', 'Cyber Attack']

    const getCountry = () => {
        if (localStorage.getItem('article')!=null) {
            let ans= JSON.parse(localStorage.getItem('article'))
            ans.fields.author_names=ans.fields.author_names.split(';');
            setArticle(null);
            setArticle(ans.fields);
            if (ans.pk !== "") {
                setDoi(ans.pk)
            }
            const clusterList = ans.fields.clusters.split(";")
            setClusterList(clusterList)
            setWordCloudList(wordCloudLoader(clusterList))
        }
    }

    useState(()=>{
        getCountry()
    })

    function toClusterPage(cluster) {
        navigate(`/cluster/${cluster}`)
        window.location.reload()
    }

    return (
        <Page>
            <CLDNavBar 
                query={query}
                docType={docType}
            />
            <div>
                {article != null ? <div>
                    {article && (<CLDDetailPage className="jumbotron">
                            <CLDDetailTitleDiv>
                                <CLDDetailTextDiv>
                                    <h1 className="display-4">{article.title}</h1>
                                    <hr className="my-4"/>
                                    <p className="lead">
                                        <p><b>Authors: </b>
                                            {article.author_names.map((n, i, names) => (
                                                (i + 1 === names.length)?
                                                (
                                                <span>{n}</span>
                                                )
                                                    :
                                                (
                                                    <span>{n} | </span>
                                                )
                                            ))}</p>
                                    </p>
                                    {article.authkeywords && <p className="lead">
                                        <p>{article.authkeywords}</p>
                                    </p>}
                                    {(doi !== "") && <p className="lead">
                                        <a href={`https://doi.org/${doi}`}>Article Link</a>
                                    </p>}

                                    {/*<h3>{article.publicationName}</h3>*/}
                                </CLDDetailTextDiv>
                            </CLDDetailTitleDiv>
                            <CLDDetailDesDiv>
                                <CLDDetailTextDiv>
                                    <hr className="my-4"/>
                                    <p>{article.description}</p>
                                    <hr className="my-4"/>
                                    <h5>Cited by: {article.citedby_count}</h5>
                                </CLDDetailTextDiv>
                            </CLDDetailDesDiv>
                            <CLDDetailCardDiv>
                                <CLDDetailClusterCard>

                                    <Card.Body>
                                        <Card.Title>This literature piece is in the following cluster(s):</Card.Title>
                                        <WordCloudContainer>
                                            {clusterList.map(cluster => (
                                                    <WordCloudDiv key={cluster}>
                                                        <WordCloudImg onClick={() => toClusterPage(cluster)}
                                                                      src={require(`./images/wordcloud_cluster_${cluster}_pre.png`)}
                                                                      alt={cluster}/>
                                                        <WordCloudP>{cluster_names[cluster]}</WordCloudP>
                                                    </WordCloudDiv>
                                                )
                                            )}
                                        </WordCloudContainer>
                                    </Card.Body>
                                </CLDDetailClusterCard>
                            </CLDDetailCardDiv>
                        <CLDFooter />
                    </CLDDetailPage>)}
                </div> : 'hello'}
            </div>

        </Page>
    )
}
