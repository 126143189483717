import React, {useState} from "react"
import Plot from 'react-plotly.js';
import CLDNavBar from "./styledComponents/CLDNavBar";
import {CLDFooter} from "./styledComponents/CLDFooter";
import {useParams} from "react-router";
import CLDPCA from "./styledComponents/CLDPCA";
import {
    WordCloudContainer,
    WordCloudDiv, WordCloudFocusDiv,
    WordCloudImg,
    WordCloudImgFocus,
    WordCloudP
} from "./styledComponents/CLDDetail";
import {useNavigate} from "react-router-dom";


// const graph = require("./DataVis/network.html")

export default function ClusterDetail() {
    const navigate = useNavigate()
    const {clusterId} = useParams()
    const cluster_names = ['Detection', 'Cyber Terminology', 'Cyber System Management', 'Cyber Regulations & Public Policy', 'Healthcare',
        'Computational Intelligence', 'Mobile', 'System Security', 'Misc. I', 'Finance & Economics', 'Security Breach',
        'Misc. II', 'Technology Management', 'Malware', 'Cyberphysical Devices', 'Misc. III', 'Electronic Control',
        'Cyber Crimes', 'System Resilience', 'Cryptography', 'Algorithm', 'Data Management', 'Misc. IV',
        'Internet of Things', 'Smart Network', 'Power System', 'Assessment', 'Learning', 'Cyber Security', 'Cyber Attack']

    const cluster_id = []
    const useID = clusterId !== undefined && clusterId >= 0 && clusterId <= cluster_names.length
    if (useID) {
        cluster_id.push(clusterId)
    } else {
        for (let i = 0; i < cluster_names.length; i++) cluster_id.push(i)
    }



    function filterCluster(id) {
        navigate(`/cluster/${id}`)
        window.location.reload()
    }

    return(
        <div>
            <CLDNavBar/>
            {/*<div dangerouslySetInnerHTML={{ __html: graph}} />*/}
            <div>
                <CLDPCA clusterId={clusterId}/>
                <WordCloudContainer>
                    {
                        useID ? (
                            cluster_id.map(cluster => (
                                    <WordCloudFocusDiv key={cluster}>
                                        <WordCloudImgFocus onClick={() => filterCluster(cluster)}
                                                           src={require(`./images/wordcloud_cluster_${cluster}.png`)}
                                                           alt={cluster}/>
                                        <WordCloudP>{cluster_names[cluster]}</WordCloudP>
                                    </WordCloudFocusDiv>
                                ))
                        ) : (
                            cluster_id.map(cluster => (
                                <WordCloudDiv key={cluster}>
                                    <WordCloudImg onClick={() => filterCluster(cluster)}
                                                  src={require(`./images/wordcloud_cluster_${cluster}_pre.png`)}
                                                  alt={cluster}/>
                                    <WordCloudP>{cluster_names[cluster]}</WordCloudP>
                                </WordCloudDiv>
                            ))
                        )
                    }
                </WordCloudContainer>
            </div>
            <CLDFooter/>
        </div>
    )
}
